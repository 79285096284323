import axios from "axios";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Logo } from "../Logo";
import { useAuth } from "../../hooks/useAuthContext";
import MenuBar from "../MenuBar";
import { BsPower, BsPersonBoundingBox, BsChatHeartFill } from "react-icons/bs";
import { PiCoinVerticalFill } from "react-icons/pi";
import NotificationButton from "../NotificationButton";
import { TbSunHigh, TbMoon } from "react-icons/tb";
import { MdFamilyRestroom, MdPhoneInTalk, MdSchool } from "react-icons/md";
import { IoMdMailOpen } from "react-icons/io";
import {
  RiAccountPinCircleFill,
  RiAddCircleFill,
  RiHome4Fill,
} from "react-icons/ri";
import {
  Button,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  useColorMode,
  useColorModeValue,
  IconButton,
  HStack,
  Tooltip,
  Image,
  VStack,
} from "@chakra-ui/react";
import RegisterAddressAlert from "../RegisterAddressAlert";

const orders = [
  "خـــان‍ـــــه",
  "آکادمی",
  "پرداخت",
  "نامه‌ به یار",
  "شــمــیم مــانـــدگــار",
  "گــروه‌هـای استـــانی",
  "ایـجـــاد پــویـش‌هـــا",
  "شبکه‌های‌اجتماعی",
  "دعوت از دوســتـــان",
  "پشتـیبــانی سامـانه",
  "راههای‌ ارتـبـاط‌ با ما",
  "نـصـب اپـلیـکــیـشن",
];

const menuData = [
  {
    text: orders[0], //for less width
    link: "/",
    icon: <RiHome4Fill />,
    display: { base: "inline-flex", md: "none" },
  },
  {
    text: orders[1], //for less width
    link: "https://edu.shamim313.com/",
    icon: <MdSchool />,
    display: { base: "inline-flex", md: "none" },
  },
  {
    text: "پرداخت",
    link: "/islamic-payment",
    icon: <PiCoinVerticalFill />,
  },
  {
    text: "نامه به یار",
    link: "/u/arizeh",
    icon: <IoMdMailOpen />,
  },
  {
    text: orders[4],
    link: "/f",
    icon: <MdFamilyRestroom />,
  },
  {
    text: "گــروه‌هـای استـــانی",
    link: "/cities",
    icon: <RiAccountPinCircleFill />,
  },
  {
    text: "ایـجـــاد پــویـش‌هـــا",
    link: "/u/e",
    icon: <RiAddCircleFill />,
    display: { base: "inline-flex", md: "none" },
  },
  {
    text: orders[7],
    link: "/channels",
    icon: <BsChatHeartFill />,
  },
  {
    text: orders[10],
    link: "/contact",
    icon: <MdPhoneInTalk />,
  },
];

function Header() {
  const toast = useToast();
  const navigate = useNavigate();
  const {
    token,
    sid,
    BASE_URL,
    userAvatar,
    isAuthenticated,
    isVip,
    getUserInfo,
    signOut,
    checkForInvalidToken,
  } = useAuth();
  const userInfo = getUserInfo();
  const { toggleColorMode } = useColorMode();
  const SwitchIcon = useColorModeValue(TbMoon, TbSunHigh);

  // Send logout request
  const logOutRequest = () => {
    const logOutPromise = new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_URL}/user/logout/`, {
          headers: {
            Authorization: "Bearer " + token,
            Sid: sid,
          },
        })
        .then(() => {
          signOut();
          navigate("/signin", { replace: true });
          resolve("");
        })
        .catch((e) => {
          if (checkForInvalidToken(e)) return;

          toast({
            title: `کد خطا: ${e.response?.status || "نامشخص"}`,
            description: JSON.stringify(e.response?.data, null, 2),
            status: "error",
            duration: 4000,
            isClosable: true,
          });

          reject();
        });
    });

    toast.promise(logOutPromise, {
      success: { title: "خارج شدید" },
      error: { title: "عملیات ناموفق بود!" },
      loading: { title: "درحال خروج از حساب ..." },
    });
  };

  return (
    <VStack alignItems="stretch" spacing="1.5rem" p="1rem 0">
      <HStack alignItems="center" justifyContent="space-between">
        <HStack spacing="1rem">
          <MenuBar menuItem={menuData} />

          <RouterLink to="/">
            <Logo maxW="50px" />
          </RouterLink>

          {isAuthenticated ? (
            <RouterLink to="/f">
              <Image
                w="5rem"
                src="/images/shamim-family.png"
                {...(isVip === true
                  ? {
                      animation:
                        "glowـanim .75s cubic-bezier(.17,.67,.38,1.3) infinite alternate",
                    }
                  : {
                      filter: "grayscale(100%)",
                      opacity: 0.24,
                    })}
              />
            </RouterLink>
          ) : null}
        </HStack>

        <HStack
          display={{
            base: "none",
            lg: "flex",
          }}
          spacing="1.5rem"
        >
          {menuData.map((item, index) => {
            return (
              <Tooltip
                key={`ExpandedHeaderItem-${index}`}
                label={item.text}
                hasArrow
              >
                <IconButton
                  aria-label="Header Icon Button"
                  variant="ghost"
                  icon={item.icon}
                  as={RouterLink}
                  to={item.link}
                  display={item.display}
                  fontSize="2xl"
                />
              </Tooltip>
            );
          })}
        </HStack>

        {isAuthenticated ? (
          <HStack spacing=".75rem">
            <NotificationButton />

            <Menu>
              <MenuButton>
                <Avatar
                  src={userAvatar}
                  name={userInfo.name}
                  width="45px"
                  height="45px"
                />
              </MenuButton>

              <MenuList>
                <MenuItem icon={<SwitchIcon />} onClick={toggleColorMode}>
                  {TbSunHigh === SwitchIcon ? "حالت روز" : "حالت شب"}
                </MenuItem>

                <MenuItem
                  icon={<BsPersonBoundingBox />}
                  as={RouterLink}
                  to="/u/p"
                >
                  ویرایش پروفایل
                </MenuItem>

                <MenuItem
                  icon={<BsPower />}
                  color="red"
                  onClick={() => {
                    logOutRequest();
                  }}
                >
                  خروج از حساب
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        ) : (
          <Button as={RouterLink} to="/signin" variant="outline">
            ورود / ثبت‌نام
          </Button>
        )}
      </HStack>

      <RegisterAddressAlert />
    </VStack>
  );
}

export default Header;
