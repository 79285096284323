import { Box, Flex, HStack, StackProps, Text, VStack } from "@chakra-ui/react";
import { useMemo, useState } from "react";

type ActivityChartProps = {
  data: { date: string; counts: number }[];
  description?: string;
  styles?: StackProps;
};

export default function ActivityChart(props: ActivityChartProps) {
  const [hoveredDot, setHoveredDot] = useState<{
    date: string;
    counts: number;
  } | null>(null);

  const details = useMemo(() => {
    let counts = 0;
    let minCounts = 0;
    let maxCounts = 0;

    for (const index in props.data) {
      const item = props.data[index];
      counts += item.counts;
      if (item.counts > maxCounts) maxCounts = item.counts;
      if (item.counts < minCounts) minCounts = item.counts;
    }

    return {
      description:
        props.description?.replace("{{count}}", `${counts}`) ??
        `تعداد کل فعالیت های انجام شده: ${counts}`,
      counts,
      minCounts,
      maxCounts,
    };
  }, [props.description, props.data]);

  return (
    <VStack spacing=".75rem" alignItems="stretch" {...props.styles}>
      {props.data.length > 0 ? (
        <Text textAlign="start" fontSize="xs">
          از تاریخ{" "}
          {new Date(props.data[0].date).toLocaleDateString("fa", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          })}{" "}
          تا{" "}
          {new Date(props.data[props.data.length - 1].date).toLocaleDateString(
            "fa",
            {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            }
          )}
        </Text>
      ) : null}

      <Flex
        gap=".5rem"
        flexDir="row"
        flexWrap="wrap"
        onMouseLeave={() => setHoveredDot(null)}
      >
        {props.data.map((item) => (
          <Box
            key={item.date}
            w=".75rem"
            h=".75rem"
            borderRadius="sm"
            cursor="pointer"
            bgColor={
              item.counts > 0 ? "var(--c--success)" : "var(--c--outline)"
            }
            opacity={
              item.counts > 0 ? item.counts / details.maxCounts + 0.36 : 1
            }
            onMouseEnter={() => setHoveredDot(item)}
            onClick={() => setHoveredDot(item)}
          />
        ))}
      </Flex>

      <HStack>
        {hoveredDot ? (
          <Box
            w=".75rem"
            h=".75rem"
            borderRadius="sm"
            cursor="pointer"
            bgColor={
              hoveredDot.counts > 0 ? "var(--c--success)" : "var(--c--outline)"
            }
            opacity={
              hoveredDot.counts > 0
                ? hoveredDot.counts / details.maxCounts + 0.4
                : 1
            }
          />
        ) : null}

        <Text flex="1" textAlign="start" fontSize="xs">
          {hoveredDot
            ? `${hoveredDot.counts} فعالیت در ${new Date(
                hoveredDot.date
              ).toLocaleDateString("fa", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })} ثبت شده است.`
            : details.description}
        </Text>
      </HStack>
    </VStack>
  );
}
