import { useEffect, useState } from "react";
import {
  AuthContext,
  BASE_URL,
  loadAuth,
  loadUserAvatar,
  USER_AVATAR_KEY,
  USER_PROFILE_INFO_KEY,
} from "./hooks/useAuthContext";
import { RouterProvider } from "react-router-dom";
import AppRouter from "./router";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import PwaContext from "./utils/pwaContext";
import usePwa from "use-pwa";
import Cookies from "js-cookie";
import { useQuery } from "react-query";
import axios from "axios";

// import app base style

//TODO Lazy
import "../src/assets/fonts/iranYekan/css/fontiran.css";
import "../src/assets/css/palette.css";
import "../src/assets/css/animations.css";
import "../src/assets/css/global.css";
import "../src/assets/css/mapBoxOverrideStyles.css";
import "../src/assets/css/chackraOverrideStyle.css";
import useVIP from "./hooks/useVIP";
import ErrorPage from "./pages/Error";
import useIVP from "./hooks/IVP";

const isProduction = process.env.NODE_ENV === "production";

// const ErrorPage = lazy(() => wait(0).then(() => import("./pages/Error")));

const useErrorBoundary = () => {
  useEffect(() => {
    const handleError = (error) => {
      // Call the log API function here
      console.warn(error, isProduction);
    };

    window.addEventListener("error", (event) => {
      event.preventDefault();
      handleError(event.error);
    });

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);
};

export default function App() {
  const { checkIVP, setIVP } = useIVP();
  const [auth, setAuth] = useState(loadAuth());
  const [avatar, setAvatar] = useState(loadUserAvatar());
  const [isVip, setVip] = useState(checkIVP());
  const vipStore = useVIP();
  // useErrorBoundary();

  //Add for not show install app when cant install it
  const [canInstallApp, setCanInstallApp] = useState(false);
  const { appinstalled, enabledPwa } = usePwa();
  const [isAuthenticated, setAuthStatus] = useState(
    auth.token !== null &&
      auth.token !== undefined &&
      auth.sid !== null &&
      auth.sid !== undefined
  );

  const chakraTheme = extendTheme({
    direction: "rtl",
    useSystemColorMode: true,
    fonts: {
      heading: `'IRANYekan' , sans-serif`,
      body: `'IRANYekan' , sans-serif`,
    },
  });

  const chakraToastOptions = { defaultOptions: { position: "top" } };

  useQuery({
    queryKey: ["getUserInfoForFirstTimeQuery"],
    staleTime: Infinity,
    cacheTime: 0,
    retry: 0,
    queryFn: async () => {
      const token = Cookies.get("token");

      if (token) {
        const profileResult = await axios.get(
          `${BASE_URL}/profile/?full=true`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        localStorage.setItem(USER_AVATAR_KEY, profileResult.data.data.avatar);
        setAvatar(profileResult.data.data.avatar);

        //TODO CHECK MODEL
        vipStore.setVip(profileResult.data.data.vip);
        vipStore.setProfile(profileResult.data.data);

        if (profileResult.data.data.vip) {
          setIVP();
          setVip(true);
        }

        Cookies.set(
          USER_PROFILE_INFO_KEY,
          JSON.stringify({
            phone: profileResult.data.data.mobile,
            name: `${profileResult.data.data.first_name} ${profileResult.data.data.last_name}`,
            email: profileResult.data.data.email || "",
            gender: profileResult.data.data.gender || "",
            province: profileResult.data.data.province || "",
            city: profileResult.data.data.city || "",
          }),
          { expires: 365 }
        );

        return profileResult.data.data;
      }
    },
  });

  useEffect(() => {
    const eventHandler = (event) => {
      // Prevent the default prompt
      event.preventDefault();
      // Set flag to indicate PWA can be installed
      setCanInstallApp(true);
    };
    window.addEventListener("beforeinstallprompt", eventHandler);
    return () =>
      window.removeEventListener("beforeinstallprompt", eventHandler);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        isAuthenticated,
        avatar,
        isVip,
        setAuth,
        setAuthStatus,
        setAvatar,
        setVip,
      }}
    >
      <PwaContext.Provider value={{ canInstallApp, appinstalled, enabledPwa }}>
        <ChakraProvider theme={chakraTheme} toastOptions={chakraToastOptions}>
          <RouterProvider router={AppRouter} fallbackElement={<ErrorPage />} />
        </ChakraProvider>
      </PwaContext.Provider>
    </AuthContext.Provider>
  );
}
