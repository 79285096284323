import {
  Button,
  Card,
  CardBody,
  CardProps,
  Heading,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { RiFileCopy2Line } from "react-icons/ri";

type ShareEventCardProps = {
  eventId?: string;
  isOwner?: boolean;
  publicationState?: string;
  styles?: CardProps;
};

export default function ShareEventCard(props: ShareEventCardProps) {
  const toast = useToast();

  if (
    props.eventId &&
    props.isOwner === true &&
    props.publicationState === "public"
  ) {
    return (
      <Card className="rising_card" variant="outline" {...props.styles}>
        <CardBody as={VStack} spacing="1rem" alignItems="stretch">
          <VStack spacing=".5rem" alignItems="stretch">
            <Heading
              size="sm"
              color="getEventQuery.data?.event.publication_state"
            >
              اشتراک گذاری پویش
            </Heading>

            <Text
              color="getEventQuery.data?.event.publication_state"
              fontSize="sm"
              opacity=".63"
            >
              برای اشتراک گذاری لینک پویش میتوانید روی یکی از گزینه های زیر کلیک
              کنید.
            </Text>
          </VStack>

          <HStack justifyContent="left" spacing=".5rem">
            <Button
              size="sm"
              colorScheme="telegram"
              variant="outline"
              leftIcon={<RiFileCopy2Line />}
              onClick={() => {
                if (window.navigator.clipboard) {
                  window.navigator.clipboard.writeText(
                    `https://shamim313.com/u/e/${props.eventId}`
                  );

                  toast({
                    colorScheme: "green",
                    title: "لینک پویش با موفقیت کپی شد!",
                  });
                } else {
                  toast({
                    colorScheme: "red",
                    title: "لینک کپی نشد!",
                    description:
                      "متاسفانه مرورگر شما از این امکان پشتیبانی نمی کند.",
                  });
                }
              }}
            >
              کپی کردن
            </Button>

            <Button
              size="sm"
              colorScheme="telegram"
              onClick={() => {
                window.navigator
                  .share({
                    url: `https://shamim313.com/u/e/${props.eventId}`,
                  })
                  .catch((e) => {
                    toast({
                      colorScheme: "orange",
                      title: "لینک اشتراک گذاری نشد!",
                      description:
                        "ممکن است به خاطر عدم پشتیبانی مرورگر شما از این امکان باشد.",
                    });
                  });
              }}
            >
              اشتراک گذاری
            </Button>
          </HStack>
        </CardBody>
      </Card>
    );
  }

  return <></>;
}
